import { connect } from "react-redux";
import { store } from "../store/store";

function MerchandiseUI(props: {
    key: number;
    title: string;
    type: string;
    frontImg: string;
    backImg: string;
    price: number;
    description: string;
}) {
    function addItemToCart() {
        store.dispatch({
            type: "STORE_ITEM_IN_CART",
            payload: {
                title: props.title,
                type: props.type,
                price: (props.price * 0.01).toFixed(2),
            },
        });
    }

    return (
        <div
            id="merchandise-ui-div"
            className="card px-2 mb-5 border-right-0 border-left-0 border-top-0 border-dark rounded-0"
        >
            <div id="merchandise-ui-img-container">
                <img
                    id="merchandise-ui-img"
                    className="w-100 "
                    src={props.frontImg}
                    alt="arrow-right"
                />
            </div>
            <div className="bg-white px-3">
                <h4 className="mt-1">{props.title}</h4>
                <p id="merchandise-ui-description">{props.description}</p>
                <p>${(props.price * 0.01).toFixed(2)}</p>
                <div className="">
                    <button className="btn btn-primary">Item Page</button>
                    <button
                        className="btn btn-primary float-right"
                        onClick={() => {
                            addItemToCart();
                        }}
                    >
                        Add to Cart
                    </button>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: any) {
    return {
        cartArr: state.cartArr,
    };
}

export default connect(mapStateToProps)(MerchandiseUI);
