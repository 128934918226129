import { useState } from "react";
import cart from "../images/logos/cart.svg";
import caretUp from "../images/logos/caret-up.svg";
import caretDown from "../images/logos/caret-down.svg";
import { connect } from "react-redux";
import trash from "../images/logos/trash.svg";
import { store } from "../store/store";
import checkout from "../images/logos/checkout.svg";
import embeddedX from "../images/logos/embedded-x.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function CartUI(props: any) {
    const [itemListDisplay, setItemListDisplay] = useState("inline-block");
    const [caretDownDisplay, setCaretDownDisplay] = useState("none");

    function invertItemListDisplayState() {
        if (itemListDisplay === "inline-block") {
            setItemListDisplay("none");
        } else {
            setItemListDisplay("inline-block");
        }
    }
    function invertCaretDownState() {
        if (caretDownDisplay === "inline-block") {
            setCaretDownDisplay("none");
        } else {
            setCaretDownDisplay("inline-block");
        }
    }

    function howManyOfAnItem(itemName: string) {
        const filteredCartItems = props.cartArr.filter(
            (cartObj: any, index: number) => cartObj.title === itemName
        );
        return filteredCartItems.length;
    }
    function filteredForDuplicates() {
        return props.cartArr.filter(
            (cartObj: any, index: number, self: any) =>
                index ===
                self.findIndex((obj: any) => obj.title === cartObj.title)
        );
    }

    function findFirstMatchingItem(title: string) {
        return props.cartArr.map((obj: any) => obj.title).indexOf(title);
    }

    function removeItemFromCart(title: string) {
        store.dispatch({
            type: "REMOVE_ITEM_FROM_CART",
            payload: findFirstMatchingItem(title),
        });
    }

    function clearCart() {
        store.dispatch({
            type: "CLEAR_CART",
        });
    }

    const location = useLocation();

    function makeSurePathIsNotCheckout() {
        if (location.pathname !== "/checkout") {
            return true;
        } else return false;
    }

    return (
        <>
            {props.cartArr.length > 0 && makeSurePathIsNotCheckout() && (
                <div className="col-12 bg-danger text-center text-white pt-2">
                    <div className="mb-2">
                        <div className="text-underlined text-white">
                            <img
                                className="d-inline-block svg-white ml-1"
                                src={cart}
                                alt="shopping-cart"
                                width="26"
                            />
                            <img
                                className="position-absolute svg-white mt-1 ml-3"
                                src={caretUp}
                                alt="show cart"
                                width="20"
                                style={{ display: itemListDisplay }}
                                onClick={() => {
                                    invertItemListDisplayState();
                                    invertCaretDownState();
                                }}
                            />
                            <img
                                className="position-absolute svg-white mt-1 ml-3"
                                src={caretDown}
                                alt="show cart"
                                width="20"
                                style={{ display: caretDownDisplay }}
                                onClick={() => {
                                    invertCaretDownState();
                                    invertItemListDisplayState();
                                }}
                            />
                        </div>
                    </div>
                    <div className="" style={{ display: itemListDisplay }}>
                        <table className="mt-1 mb-2">
                            <tbody>
                                {filteredForDuplicates().map(
                                    (cartObj: any, index: number) => {
                                        return (
                                            <tr key={index} className="">
                                                <td className="py-1 text-left">
                                                    {cartObj.title}
                                                </td>
                                                <td className="pl-3">
                                                    {cartObj.type}
                                                </td>
                                                <td className="px-3">
                                                    ${cartObj.price}
                                                </td>
                                                <td>
                                                    (
                                                    {howManyOfAnItem(
                                                        cartObj.title
                                                    )}
                                                    )
                                                </td>
                                                <td className="text-right">
                                                    <img
                                                        className="clickable svg-white mt-n1"
                                                        src={trash}
                                                        alt="delete"
                                                        onClick={() => {
                                                            removeItemFromCart(
                                                                cartObj.title
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                        <div className="d-block mb-1">
                            <button
                                className="link clickable btn"
                                onClick={() => {
                                    clearCart();
                                }}
                            >
                                <img
                                    className="mb-1 mr-1"
                                    src={embeddedX}
                                    alt="clear cart"
                                />
                                Clear Cart
                            </button>
                            <Link to="/checkout" className="link clickable btn">
                                Check Out
                                <img
                                    className="mb-1 ml-1"
                                    src={checkout}
                                    alt="checkout"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        cartArr: state.cartArr,
    };
}

export default connect(mapStateToProps)(CartUI);
