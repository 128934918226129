interface clothingPiece {
    id: string;
    title: string;
    type: string;
    frontImg: string;
    backImg: string;
    price: number;
    inventory: number;
    description: string;
}

interface clothingPieceObjects extends Array<clothingPiece> {}

const shirtObjects: clothingPieceObjects = [
    {
        id: "1dc00a88-3a93-4ee5-a33e-5b6ed3e287b8",
        title: "The Original",
        type: "shirt",
        frontImg: "src/images/products/obamagay-front.png",
        backImg: "src/images/products/obamagay-back.png",
        price: 1999,
        inventory: 27,
        description: "The Upset Original",
    },
    {
        id: "f29aad57-b6ad-4397-b0ca-3138755da0d6",
        title: "The Marxist",
        type: "shirt",
        frontImg: "src/images/products/aoc-front.png",
        backImg: "src/images/products/aoc-back.png",
        price: 1999,
        inventory: 14,
        description: "The Marxists aren't gonna like this",
    },
    {
        id: "655c56ca-50fd-4b8a-af7e-035d427322e5",
        title: "The Libtard",
        type: "shirt",
        frontImg: "src/images/products/libtard.jpeg",
        backImg: "",
        price: 1999,
        inventory: 9,
        description: "Those with blue hair will not take kindly",
    },
    {
        id: "b6e56deb-df8a-4be3-b3d9-8ad329294866",
        title: "The American",
        type: "shirt",
        frontImg: "src/images/products/offended.jpg",
        backImg: "",
        price: 1999,
        inventory: 39,
        description: "You can like it or leave it!",
    },
];

export type { clothingPiece };
export { shirtObjects };
