import twitter from "../images/logos/twitter.svg";
import facebook from "../images/logos/facebook.svg";
import instagram from "../images/logos/instagram.svg";
import whatsapp from "../images/logos/whatsapp.svg";

export default function Footer() {
  return (
    <div className="row my-5">
      <div className="col-10 offset-1 mt-5 text-center">
        <h3>
          Offend <span style={{ fontSize: "16px" }}>the</span> Masses
        </h3>
        <div className="col m-auto">
          <img
            className="footer-social-logos clickable"
            src={twitter}
            alt="twitter"
          />
          <img
            className="footer-social-logos clickable"
            src={facebook}
            alt="facebook"
          />
          <img
            className="footer-social-logos clickable"
            src={instagram}
            alt="instagram"
          />
          <img
            className="footer-social-logos clickable"
            src={whatsapp}
            alt="whatsapp"
          />
        </div>
        <h6>©2021 obamagay.com</h6>
      </div>
    </div>
  );
}
