export default function ArticleUI(props: {
  key: number;
  title: string;
  subtitle: string;
  date: string;
  author: string;
  imgUrl: string;
}) {
  return (
    <div id="article-ui-div" className="border-bottom border-dark py-2 my-3">
      <h3 className="">{props.title}</h3>
      <h5 className="text-muted">{props.subtitle}</h5>
      <img src={props.imgUrl} className="w-100" alt={props.title} />
      <div className="pt-2 px-4">
        <h6 className="d-inline-block align-middle">{props.author}</h6>
        <h6 className="d-inline-block float-right align-middle">
          {props.date}
        </h6>
      </div>
    </div>
  );
}
