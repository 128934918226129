import { useState } from "react";
import { Link } from "react-router-dom";
import menu from "../images/logos/menu.svg";
import x from "../images/logos/x.svg";
import book from "../images/logos/book.svg";
import cart from "../images/logos/cart.svg";
import people from "../images/logos/people.svg";
import login from "../images/logos/login.svg";
import CartUI from "./cartUI";

export default function MobileNav() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <>
            <div className="row shadow sticky-top bg-white d-md-none">
                <div className="col-10 offset-1 my-1 py-2">
                    <div>
                        <Link to="/" className="text-dark d-inline-block mt-2">
                            <h3 className="d-inline">Offend </h3>
                            <p
                                className="d-inline"
                                style={{ fontSize: "12px" }}
                            >
                                the
                            </p>
                            <h3 className="d-inline"> Masses</h3>
                        </Link>
                        {isNavExpanded === false && (
                            <img
                                className={"float-right mt-1 clickable"}
                                width={40}
                                src={menu}
                                alt="menu open/close"
                                onClick={() => {
                                    setIsNavExpanded(!isNavExpanded);
                                }}
                            />
                        )}
                        {isNavExpanded && (
                            <img
                                className={"float-right clickable"}
                                width={50}
                                style={{ marginRight: "-6px" }}
                                src={x}
                                alt="menu open/close"
                                onClick={() => {
                                    setIsNavExpanded(!isNavExpanded);
                                }}
                            />
                        )}
                    </div>
                    <div className="row pt-4" hidden={!isNavExpanded}>
                        <div className="col-sm text-sm-left text-center">
                            <Link
                                to="/merchandise"
                                className="mb-3 text-dark px-1 d-block"
                            >
                                <img
                                    src={cart}
                                    className="d-inline-block mb-1 mr-1"
                                    alt="cart"
                                />
                                <h4
                                    className="d-inline-block"
                                    style={{ textDecoration: "underline" }}
                                >
                                    Merchandise
                                </h4>
                            </Link>
                            <Link
                                to="/blog"
                                className="mb-3 text-dark px-1 d-block"
                            >
                                <img
                                    src={people}
                                    className="d-inline-block mb-1 mr-1"
                                    alt="people"
                                />
                                <h4
                                    className="d-inline-block"
                                    style={{ textDecoration: "underline" }}
                                >
                                    Blog
                                </h4>
                            </Link>
                        </div>
                        <div className="col-sm text-sm-left text-center">
                            <Link
                                to="/literature"
                                className="mb-3 text-dark px-1 d-block"
                            >
                                <img
                                    src={book}
                                    className="d-inline-block mb-1 mr-1"
                                    alt="book"
                                />
                                <h4
                                    className="d-inline-block "
                                    style={{ textDecoration: "underline" }}
                                >
                                    Literature
                                </h4>
                            </Link>
                            <Link
                                to="/login-signup"
                                className="mb-3 text-dark px-1 d-block"
                            >
                                <img
                                    src={login}
                                    className="d-inline-block mb-1 mr-1"
                                    alt="login"
                                />
                                <h4
                                    className="d-inline-block"
                                    style={{ textDecoration: "underline" }}
                                >
                                    Login/Signup
                                </h4>
                            </Link>
                        </div>
                    </div>
                </div>
                <CartUI />
            </div>
        </>
    );
}
