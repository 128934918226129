import arrowFill from "../images/logos/arrow-fill.svg";

export default function BookUI(props: {
    key: number;
    title: string;
    subtitle: string;
    author: string;
    excerpt: string;
    description: string;
}) {
    return (
        <div id="book-ui-div" className="card shadow-lg py-3">
            <div className="border-bottom border-dark">
                <h3 className="text-center">
                    <b>{props.title}</b>
                </h3>
                <h4 className="text-center">{props.subtitle}</h4>
            </div>

            <div className="pt-1">
                <h5 className="text-center">
                    By: <b>{props.author}</b>
                </h5>
                <h5 className="pt-4">Notable Passage:</h5>
                <p style={{ fontSize: "14px" }}>"{props.excerpt}"</p>
            </div>
            <div className="pt-2">
                <a
                    href="https://www.amazon.com/Americas-First-Gay-President-Homosexual-ebook/dp/B00U9RJON8"
                    className="btn btn-outline-dark float-right"
                >
                    <p className="d-inline">See More</p>
                    <img
                        className="mb-1 ml-2"
                        src={arrowFill}
                        alt="arrow-right"
                    />
                </a>
            </div>
        </div>
    );
}
