import React from "react";
import Footer from "../../functional_components/footer";
import MobileNav from "../../functional_components/mobileNav";
import MerchandiseUI from "../../functional_components/merchandiseUI";
import { shirtObjects } from "../../dummy-data/clothes";
import accFront from "../../images/products/aoc-front.png";
import obamagayFront from "../../images/products/obamagay-front.png";
import libtard from "../../images/products/libtard.jpeg";
import offended from "../../images/products/offended.jpg";
import DesktopNav from "../../functional_components/desktopNav";

const imagesArr = [obamagayFront, accFront, libtard, offended];

export default class Merchandise extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <MobileNav />
                <DesktopNav />
                <div className="row py-4">
                    <h1 className="col-12 text-center">
                        Offend <span style={{ fontSize: "22px" }}>the</span>{" "}
                        Libs
                    </h1>
                </div>
                <div className="row">
                    {shirtObjects.map((clothObj, index) => {
                        const {
                            title,
                            type,
                            backImg,
                            description,
                            price,
                        } = clothObj;
                        return (
                            <MerchandiseUI
                                key={index}
                                title={title}
                                type={type}
                                frontImg={imagesArr[index]}
                                backImg={backImg}
                                description={description}
                                price={price}
                            />
                        );
                    })}
                </div>
                <Footer />
            </div>
        );
    }
}
