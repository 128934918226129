import { AnyAction, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import combineReducers from "./combineReducers";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import type { IAppState } from "./combineReducers";

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: hardSet,
};

const persistedReducer = persistReducer<IAppState, AnyAction>(
    persistConfig,
    combineReducers
);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store };
export { persistor };
