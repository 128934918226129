import React from "react";
import DesktopNav from "../../functional_components/desktopNav";
import Footer from "../../functional_components/footer";
import MobileNav from "../../functional_components/mobileNav";

export default class Landing extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <MobileNav />
                <DesktopNav />
                <div className="row">
                    <h1 className="col-10 offset-1 header-text text-center mt-5 mt-md-0">
                        Obama,
                    </h1>
                    <h1 className="col-10 offset-1 header-text text-center mt-5">
                        Gay?
                    </h1>
                    <h1 className="col-10 offset-1 header-text text-center mt-5">
                        Absolutely
                    </h1>
                </div>
                <Footer />
            </div>
        );
    }
}
