import React from "react";
import Footer from "../../functional_components/footer";
import MobileNav from "../../functional_components/mobileNav";
import { articles } from "../../dummy-data/articles";
import ArticleUI from "../../functional_components/articleUI";
import DesktopNav from "../../functional_components/desktopNav";

const names = ["Arthur Mann", "Hannah Eldridge", "Henry Rose", "Chuck Francis"];
const times = [
    "March 1, 2021",
    "August 21, 2020",
    "June 3, 2020",
    "November 4, 2020",
];

export default class Articles extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <MobileNav />
                <DesktopNav />
                <div className="row py-4">
                    <h1 className="col-12 text-center">
                        Get <span style={{ fontSize: "22px" }}>the</span> Scoop
                    </h1>
                </div>
                <div className="row pt-5">
                    {articles.map((article, index) => {
                        const { title, subtitle, imgUrl } = article;
                        return (
                            <ArticleUI
                                key={index}
                                title={title}
                                subtitle={subtitle}
                                date={times[index]}
                                author={names[index]}
                                imgUrl={imgUrl}
                            />
                        );
                    })}
                </div>
                <Footer />
            </div>
        );
    }
}
