import { format } from "date-fns";
import arrowFill from "../images/logos/arrow-fill.svg";

export default function BlogUI(props: {
  key: number;
  title: string;
  body: string;
  createdAt: number;
  updatedAt: number;
}) {
  function truncateBodyText(body: string) {
    return body.slice(0, 140) + "...";
  }

  function determineMoreRecentDate(date1: number, date2: number) {
    if (date1 >= date2) {
      return format(date1, "PP p");
    } else return format(date2, "PP p");
  }
  return (
    <div id="blog-ui-div" className="my-3">
      <h3 className="">{props.title}</h3>
      <p className="text-muted">
        {determineMoreRecentDate(props.createdAt, props.updatedAt)}
      </p>
      <h4 className="mb-3">{truncateBodyText(props.body)}</h4>
      <button className="btn btn-primary btn-block">
        Read Post
        <img
          id="blog-ui-button"
          className="mb-1 ml-1"
          src={arrowFill}
          style={{ color: "white" }}
          alt="arrow-right"
        />
      </button>
    </div>
  );
}
