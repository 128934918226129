import React from "react";
import { blogs } from "../../dummy-data/blogs";
import BlogUI from "../../functional_components/blogUI";
import DesktopNav from "../../functional_components/desktopNav";
import Footer from "../../functional_components/footer";
import MobileNav from "../../functional_components/mobileNav";
import search from "../../images/logos/search.svg";

export default class Blog extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <MobileNav />
                <DesktopNav />
                <div className="row py-4">
                    <h1 className="col-12 text-center">
                        Checkout <span style={{ fontSize: "22px" }}>the</span>{" "}
                        Blog
                    </h1>
                </div>
                <div className="form-row">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4 m-auto input-group">
                        <input className="form-control" placeholder="Search" />
                        <div className="input-group-append clickable">
                            <div className="input-group-text">
                                <img
                                    className=""
                                    src={search}
                                    alt="search-enter"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {blogs.map((blogObj, index) => {
                        const { title, body, createdAt, updatedAt } = blogObj;
                        return (
                            <BlogUI
                                key={Math.random()}
                                title={title}
                                body={body}
                                createdAt={createdAt}
                                updatedAt={updatedAt}
                            />
                        );
                    })}
                </div>
                <Footer />
            </div>
        );
    }
}
