import { Link } from "react-router-dom";
import book from "../images/logos/book.svg";
import tags from "../images/logos/tags.svg";
import people from "../images/logos/people.svg";
import login from "../images/logos/login.svg";
import CartUI from "./cartUI";

export default function DesktopNav() {
    //ONLY SHOW CART IF THERE ARE ITEMS IN IT
    return (
        <>
            <div className="row d-none d-md-flex my-4">
                <div className="col-4 text-center">
                    <div className="d-inline-block my-1 py-2 ml-4">
                        <Link to="/" className="text-dark mt-2">
                            <h2 className="d-inline logo-text">Offend</h2>
                            <p
                                className="d-inline"
                                style={{ fontSize: "10px" }}
                            >
                                the
                            </p>
                            <h2 className="d-inline logo-text">Masses</h2>
                        </Link>
                    </div>
                </div>
                <div className="col-8 text-center">
                    <div className="my-1 py-2 mx-auto">
                        <Link
                            to="/merchandise"
                            className="d-inline-block mb-3 text-dark px-2 mx-lg-4"
                        >
                            <img src={tags} className=" mb-1 mr-1" alt="cart" />
                            <h4
                                className=""
                                style={{ textDecoration: "underline" }}
                            >
                                Merchandise
                            </h4>
                        </Link>
                        <Link
                            to="/literature"
                            className="d-inline-block mb-3 text-dark px-2 mx-lg-4"
                        >
                            <img src={book} className=" mb-1 mr-1" alt="book" />
                            <h4
                                className=" "
                                style={{ textDecoration: "underline" }}
                            >
                                Literature
                            </h4>
                        </Link>
                        <Link
                            to="/blog"
                            className="d-inline-block mb-3 text-dark px-2 mx-lg-4"
                        >
                            <img
                                src={people}
                                className=" mb-1 mr-1"
                                alt="people"
                            />
                            <h4
                                className=""
                                style={{ textDecoration: "underline" }}
                            >
                                Blog
                            </h4>
                        </Link>

                        <Link
                            to="/login-signup"
                            className="d-inline-block mb-3 text-dark px-2 mx-lg-4"
                        >
                            <img
                                src={login}
                                className=" mb-1 mr-1"
                                alt="login"
                            />
                            <h4
                                className=""
                                style={{ textDecoration: "underline" }}
                            >
                                Login/Signup
                            </h4>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row d-none d-md-flex sticky-top mt-n5 mb-3 py-0">
                <CartUI />
            </div>
        </>
    );
}
