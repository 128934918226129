import react from "react";
import { connect } from "react-redux";
import DesktopNav from "../../functional_components/desktopNav";
import MobileNav from "../../functional_components/mobileNav";
import trash from "../../images/logos/trash.svg";
import backArrow from "../../images/logos/back-arrow.svg";

class Checkout extends react.Component<any, {}> {
    constructor(props: {}) {
        super(props);
        this.state = {
            cardInfo: {},
            shippingAddress: {},
            billingAddress: {},
        };
    }

    howManyOfAnItem(itemName: string) {
        const filteredCartItems = this.props.cartArr.filter(
            (cartObj: any, index: number) => cartObj.title === itemName
        );
        return filteredCartItems.length;
    }
    filteredForDuplicates() {
        return this.props.cartArr.filter(
            (cartObj: any, index: number, self: any) =>
                index ===
                self.findIndex((obj: any) => obj.title === cartObj.title)
        );
    }

    findFirstMatchingItem(title: string) {
        return this.props.cartArr.map((obj: any) => obj.title).indexOf(title);
    }

    removeItemFromCart(title: string) {
        this.props.dispatch({
            type: "REMOVE_ITEM_FROM_CART",
            payload: this.findFirstMatchingItem(title),
        });
    }

    findTotal() {
        return this.props.cartArr
            .reduce((a: any, b: any) => a + Number(b.price), 0)
            .toFixed(2);
    }

    render() {
        return (
            <div className="container-fluid">
                <MobileNav />
                <DesktopNav />
                <div className="row py-4">
                    <div className="col-12 col-sm-10 m-auto">
                        <button
                            className="link clickable btn"
                            onClick={() => this.props.history.goBack()}
                        >
                            <img
                                className="mr-1 "
                                width="20px"
                                src={backArrow}
                                style={{ marginBottom: "2px" }}
                                alt="back"
                            />
                            Back
                        </button>
                    </div>

                    <h1 className="col-12 text-center">Checkout</h1>
                </div>
                <div className="row">
                    <table className="col-12 col-sm-10 col-md-8 col-lg-5 m-auto ">
                        <thead className="text-center">
                            <tr className="">
                                <th>item</th>
                                <th>type</th>
                                <th>cost</th>
                                <th>count</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {this.filteredForDuplicates().map(
                                (cartObj: any, index: number) => {
                                    return (
                                        <tr
                                            key={index}
                                            className="border-top border-bottom"
                                        >
                                            <td className="py-1">
                                                {cartObj.title}
                                            </td>
                                            <td className="">{cartObj.type}</td>
                                            <td className="">
                                                ${cartObj.price}
                                            </td>
                                            <td>
                                                (
                                                {this.howManyOfAnItem(
                                                    cartObj.title
                                                )}
                                                )
                                            </td>
                                            <td className="">
                                                <img
                                                    className="clickable "
                                                    src={trash}
                                                    alt="delete"
                                                    onClick={() => {
                                                        this.removeItemFromCart(
                                                            cartObj.title
                                                        );
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                            <tr className="">
                                <td colSpan={5} className="pt-3">
                                    Total: {this.findTotal()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        cartArr: state.cartArr,
    };
}

export default connect(mapStateToProps)(Checkout);
