import { combineReducers } from "redux";
import cartArr from "./reducers/cartArr";

export interface IAppState {
    cartArr: any[];
}

export default combineReducers<IAppState>({
    cartArr,
});
