import React from "react";
import BookUI from "../../functional_components/bookUI";
import DesktopNav from "../../functional_components/desktopNav";
import Footer from "../../functional_components/footer";
import MobileNav from "../../functional_components/mobileNav";

const bookObjects = [
    {
        title: "America's First Gay President",
        subtitle: "President Barack Obama, Closet Homosexual",
        author: "V.F. Christopher",
        excerpt:
            "The Executive, Legislative, and Judicial Branches of the U.S. government. Remember, these are all government employees where political correctness is the standard and obtaining a straight answer is next to impossible. These people will bend to political pressure from individuals in other parts of the government and their constituents.",
        description: `Why did Obama say his girlfriend described in his book "Dreams of my Father" was a "composite?" Where are the teenage girlfriends talking about the dates they had with the now President Barack Obama? Why are there only three women identified as girlfriends in Obama's entire life? Why did Obama’s high school friend Mia Marie Pope say Obama was an openly gay drug abuser? Why did Obama have all male roommates in his past except one? Why are there no intimate photos of Obama with his girlfriends? Why did Joan Rivers say Obama was gay and Michelle was a tranny? Why are there many more reports of Obama having gay encounters than heterosexual relationships? Why were there no serious engagements with any women except Michelle? This book is based on information reported by Net Users, Researchers, Authors and Contributors whom I call NURAACs. This group also includes mainstream news organizations that publish online. These NURAACs have revealed in their web articles what they believe to be the truth about Barack Obama’s homosexuality. These NURAAC articles reveal how the Obama White House misled America about his sexuality and his fake marriage to Michelle.`,
    },
];

export default class Literature extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <MobileNav />
                <DesktopNav />
                <div className="row py-4">
                    <h1 className="col-12 text-center">
                        Read <span style={{ fontSize: "22px" }}>the</span>{" "}
                        Literature
                    </h1>
                </div>
                <div className="row pt-5">
                    {bookObjects.map((book, index) => {
                        const {
                            title,
                            subtitle,
                            author,
                            excerpt,
                            description,
                        } = book;
                        return (
                            <BookUI
                                key={index}
                                title={title}
                                subtitle={subtitle}
                                author={author}
                                excerpt={excerpt}
                                description={description}
                            />
                        );
                    })}
                </div>
                <Footer />
            </div>
        );
    }
}
