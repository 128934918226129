import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Articles from "./class_components/pages/Articles";
import Blog from "./class_components/pages/Blog";
import Checkout from "./class_components/pages/Checkout";
import Landing from "./class_components/pages/Landing";
import Literature from "./class_components/pages/Literature";
import LoginSignup from "./class_components/pages/LoginSignup";
import Merchandise from "./class_components/pages/Merchandise";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/literature" component={Literature} />
                <Route exact path="/merchandise" component={Merchandise} />
                <Route exact path="/login-signup" component={LoginSignup} />
                <Route exact path="/articles" component={Articles} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/checkout" component={Checkout} />
            </Switch>
        </Router>
    );
}

export default App;
