import actions from "../actions";

export default function cartArr(
    cartArr: any[] = [],
    action: { type: string; payload: any }
) {
    let newCartArr: any[] = [...cartArr];
    switch (action.type) {
        case actions.STORE_ITEM_IN_CART:
            newCartArr.push(action.payload);
            return newCartArr;
        case actions.REMOVE_ITEM_FROM_CART:
            newCartArr.splice(action.payload, 1);
            return newCartArr;
        case actions.CLEAR_CART:
            return [];
        default:
            return newCartArr;
    }
}
