import React from "react";
import DesktopNav from "../../functional_components/desktopNav";
import Footer from "../../functional_components/footer";
import MobileNav from "../../functional_components/mobileNav";

export default class LoginSignup extends React.Component<
    {},
    { isSignupShown: boolean }
> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isSignupShown: true,
        };
    }
    render() {
        return (
            <div className="container-fluid">
                <MobileNav />
                <DesktopNav />
                <div className="row py-4">
                    <div className="col-12 text-center">
                        <h1>
                            Join <span style={{ fontSize: "22px" }}>the</span>{" "}
                            Community
                        </h1>
                    </div>
                    <div className="col-12 my-3"></div>
                    {this.state.isSignupShown && (
                        <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto card shadow py-3 mt-5">
                            <h3 className="text-center">Sign up!</h3>
                            <label className="text-center mt-3">Email</label>
                            <input className="form-control" />
                            <label className="text-center mt-3">Username</label>
                            <input className="form-control" />
                            <label className="text-center mt-3">Password</label>
                            <input className="form-control" />
                            <button className="btn btn-primary text-center mt-5">
                                Sign up
                            </button>
                            <button
                                className="btn btn-link text-left pl-1"
                                onClick={() => {
                                    this.setState({
                                        isSignupShown: !this.state
                                            .isSignupShown,
                                    });
                                }}
                            >
                                Already have an account?
                            </button>
                        </div>
                    )}

                    {this.state.isSignupShown === false && (
                        <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto card shadow py-3 mt-5">
                            <h3 className="text-center">Login</h3>
                            <label className="text-center mt-3">Username</label>
                            <input className="form-control" />
                            <label className="text-center mt-3">Password</label>
                            <input className="form-control" />
                            <button className="btn btn-primary text-center mt-5">
                                Login
                            </button>
                            <button
                                className="btn btn-link text-left pl-1"
                                onClick={() => {
                                    this.setState({
                                        isSignupShown: !this.state
                                            .isSignupShown,
                                    });
                                }}
                            >
                                Back to signup
                            </button>
                        </div>
                    )}

                    {/* <div className="col">
            <label></label>
            <input />
            <label></label>
            <input />
          </div> */}
                </div>
                <Footer />
            </div>
        );
    }
}
